<template>
  <v-container class="grey darken-3 mt-2 white--text" rounded>
    <v-row>
      <v-col>
        <h1 class="text-h4 font-weight-light nonselectable">
          Informations pratiques
        </h1>
      </v-col>
    </v-row>
    <v-divider dark width="66%"></v-divider>
    <v-row align="center">
      <v-col cols="12" sm="10" lg="8">
        <v-list dark outlined color="grey darken-3">
          <v-list-item
            v-for="item in listItems"
            :key="item.title"
            :href="item.external ? item.link : undefined"
            :to="item.external ? undefined : item.link"
            :target="item.external ? '_blank' : undefined"
            class="nonselectable"
          >
            <v-list-item-icon>
              <v-icon size="32">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="8" lg="4">
        <v-img src="@/assets/img/maps.jpg" class="rounded-lg"></v-img>
        <p class="font-weight-light font-italic nonselectable">
          Zone d'intervention présentielle approximative
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8" class="text-justify">
        <p class="text-h6 font-weight-light nonselectable">
          Pour toute demande d'informations, devis, maintenance, ou toute autre
          demande, n'hésitez pas à me contacter en utilisant le formulaire de
          contact à votre disposition sur le site, ou par téléphone. N'hésitez
          pas à laisser un message!
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Infos",
  data() {
    return {
      listItems: [
        {
          title: "Profil LinkedIn",
          icon: "mdi-linkedin",
          external: true,
          link: "https://www.linkedin.com/in/julienferand-dev/",
        },
        {
          title: "Malt Freelance",
          icon: "mdi-handshake",
          external: true,
          link: "https://www.malt.fr/profile/julienferand",
        },
        {
          title: "Téléphone - 07 49 04 26 04",
          icon: "mdi-cellphone-android",
          external: true,
          link: "tel:0749042604",
        },
        {
          title: "Contactez-moi !",
          icon: "mdi-email",
          external: false,
          link: "/contact",
        },
        {
          title: "Lundi au Vendredi, 8h - 19h",
          icon: "mdi-clock",
        },
      ],
    };
  },
  beforeMount() {
    window.document.title =
      "Développeur d'Applications à Toulouse - Infos pratiques";
  },
};
</script>

<style lang="scss" scoped>
.nonselectable {
  user-select: none;
}
</style>